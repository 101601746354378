<template>
  <div :class="props.mode">
    <action-button :mode="props.buttonClass.basic">
      <a
        href="https://instagram.com/lupanalua"
        rel="noopener noreferrer nofollow noindex"
        target="_blank"
      >
        <svg
          :class="`icon-size ${props.iconClass.basic}`"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
          />
          <path
            d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
          />
        </svg>
      </a>
    </action-button>
    <action-button :mode="props.buttonClass.basic">
      <a
        rel="noopener noreferrer nofollow noindex"
        href="https://x.com/lupanalua"
        target="_blank"
      >
        <svg
          :class="`icon-size-x ${props.iconClass.basic}`"
          viewBox="0 0 1200 1227"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          />
        </svg>
      </a>
    </action-button>
    <action-button :mode="props.buttonClass.basic">
      <a
        rel="noopener noreferrer nofollow noindex"
        href="https://lupanalua.bsky.social"
        target="_blank"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          :class="`icon-size ${props.iconClass.basic}`"
          viewBox="0 0 600 530"
        >
          <path
            d="m135.72 44.03c66.496 49.921 138.02 151.14 164.28 205.46 26.262-54.316 97.782-155.54 164.28-205.46 47.98-36.021 125.72-63.892 125.72 24.795 0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.3797-3.6904-10.832-3.7077-7.8964-0.0174-2.9357-1.1937 0.51669-3.7077 7.8964-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.4491-163.25-81.433-5.9562-21.282-16.111-152.36-16.111-170.07 0-88.687 77.742-60.816 125.72-24.795z"
          />
        </svg>
      </a>
    </action-button>

    <action-button :mode="props.buttonClass.basic" @click="listenToShare">
      <div class="icon-share">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :class="`icon-size ${props.iconClass.basic}`"
          viewBox="0 0 512 512"
        >
          <path
            d="M384 336a63.78 63.78 0 00-46.12 19.7l-148-83.27a63.85 63.85 0 000-32.86l148-83.27a63.8 63.8 0 10-15.73-27.87l-148 83.27a64 64 0 100 88.6l148 83.27A64 64 0 10384 336z"
          />
        </svg>
      </div>
    </action-button>
  </div>
</template>
<script setup>
import { defineProps } from "vue";

const props = defineProps(["mode", "shareUrl", "iconClass", "buttonClass"]);

/**
 * function that returns the URL to share
 */
async function listenToShare() {
  try {
    await navigator.share(props.shareUrl);
  } catch (err) {
    return;
  }
}
</script>
<style scoped>
/** style for two icons on bottom right
icon size: 2rem x 2rem, button: 3.2rem */
.social-homepage {
  margin: 0 0.5rem;
  position: fixed;
  height: calc(4.3rem * 3.2);
  width: calc(1.5rem * 3.2);
  bottom: 4rem;
  right: 4rem;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 48em) {
  .social-homepage {
    width: calc(4.3rem * 3.2);
    height: calc(1.5rem * 3.2);
    flex-direction: row;
    top: 1rem;
    right: 15rem;
  }
}

/** style for two icons on bottom left */
.social-finder {
  margin: 0;
  position: fixed;

  width: calc(1.5rem * 3.2);
  height: calc(4.3rem * 3.2);
  bottom: 7rem;
  left: 0rem;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 109em) {
  .social-finder {
    width: calc(4.3rem * 3.2);
    height: calc(1.5rem * 3.2);
    top: 1rem;
    left: 15rem;
    bottom: auto;
    flex-direction: row;
  }
}

@media only screen and (max-width: 37.5em) {
  .social-finder {
    display: none;
  }
}

.social-nav {
  margin: 0 auto;
  width: 70%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/** ICON Styling */
/* instagram - homepage */
a,
.icon-share {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-size {
  width: 65%;
  height: 65%;
}
.icon-size-x {
  width: 55%;
  height: 55%;
}

.icon--home-basic {
  fill: var(--color-primary-3);
}

.icon--home-basic:hover {
  fill: white;
}

/** Finder pages */
.icon--finder-basic {
  fill: var(--color-grey-4);
}

.icon--finder-basic:hover {
  fill: white;
}
</style>
