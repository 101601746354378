<template>
  <div @click="toggleVisibility" class="backdrop">
    <div class="content">
      <close-button @click="toggleVisibility" mode="details"></close-button>
      <base-photofiller v-if="!selected.img_author" mode="details__filler"
        ><a
          class="mindat"
          target="_blank"
          rel="noopener noreferrer nofollow noindex"
          :href="selected.img_mindat"
          >Mindat Link</a
        ></base-photofiller
      >
      <figure v-else class="figure">
        <picture class="figure--img">
          <source
            type="image/webp"
            :srcset="'./img/minerals/' + selected.idName + '_large.webp'"
          />
          <source
            media="(max-width: 768px)"
            :srcset="
              './img/minerals/' +
              selected.idName +
              '_medium-1x.jpg 1x, ./img/minerals/' +
              selected.idName +
              '_medium.jpg 2x'
            "
          />
          <img
            :srcset="'./img/minerals/' + selected.idName + '_large.jpg'"
            :alt="selected.name[1]"
          />
        </picture>
        <figcaption class="figure--description">
          <a
            :href="selected.img_url"
            target="_blank"
            rel="noopener noreferrer nofollow noindex"
            class="capitalize"
            >{{ alt_data }}</a
          >
          by
          <a
            :href="aut_url_data"
            target="_blank"
            rel="noopener noreferrer nofollow noindex"
            >{{ selected.img_author }}</a
          >, marked with
          <a
            :href="license_data"
            target="_blank"
            rel="noopener noreferrer nofollow noindex"
          >
            {{ selected.img_license }}
          </a>
          (as original)
        </figcaption>
      </figure>
      <div class="content__text">
        <h2 class="content__text--heading heading-1">{{ selected.name[1] }}</h2>
        <div class="content__text--details">
          <div class="details__item">
            <div class="details__item--1">{{ selected.symbol[0] }}:</div>
            <div class="details__item--2" v-html="selected.symbol[1]"></div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.type[0] }}:</div>
            <div class="details__item--2">{{ selected.type[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.composition[0] }}:</div>
            <div class="details__item--2">{{ selected.composition[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.crystal[0] }}:</div>
            <div class="details__item--2">{{ selected.crystal[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.cleavage[0] }}:</div>
            <div class="details__item--2">{{ selected.cleavage[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.fracture[0] }}:</div>
            <div class="details__item--2">{{ selected.fracture[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.lustre[0] }}:</div>
            <div class="details__item--2">{{ selected.lustre[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.streak[0] }}:</div>
            <div class="details__item--2">{{ selected.streak[1] }}</div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.hardness[0] }}:</div>
            <div class="details__item--2">
              {{ selected.hardness[1] }}
              {{
                selected.hardness[2] === "-1" ? "" : `- ${selected.hardness[2]}`
              }}
            </div>
          </div>
          <div class="details__item">
            <div class="details__item--1">{{ selected.density[0] }}:</div>
            <div class="details__item--2">
              {{ selected.density[1] }}
              {{
                selected.density[2] === "-1" ? "" : `- ${selected.density[2]}`
              }}
              g/cm<sup>3</sup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";

const emit = defineEmits(["toggle-visibility"]);
const selection = defineProps({
  selected: Object,
});

function toggleVisibility() {
  return emit("toggle-visibility");
}

const alt_data = computed(() => {
  if (!selection.selected.img_alt) {
    return selection.selected.name[1];
  }
  return selection.selected.img_alt;
});

const aut_url_data = computed(() => {
  const regex = /Smithsonian/g;
  if (regex.test(selection.selected.img_author)) {
    return "https://naturalhistory.si.edu";
  }
  return selection.selected.img_aut_url;
});

const license_data = computed(() => {
  if (selection.selected.img_license.match(1.0)) {
    return "https://creativecommons.org/publicdomain/zero/1.0/";
  }
  if (selection.selected.img_license.match(2.0)) {
    return "https://creativecommons.org/licenses/by/2.0/";
  }
  return "https://creativecommons.org/licenses/by/3.0/";
});
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2100;
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  border-radius: 3rem;
  background-color: var(--color-white);
  z-index: 5500;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 54.3em) {
  .content {
    width: 85%;
    height: 85%;
  }
}

@media only screen and (max-width: 40em) {
  .content {
    width: 95%;
    height: 95%;
  }
}

@media only screen and (max-width: 25.8em) {
  .content {
    width: 85%;
    height: 85%;
  }
}

figure {
  grid-column: 1/2;
  height: 100%;
  background-color: var(--color-primary-3);
  display: block;
  display: grid;
  grid-template-rows: 80% 20%;
  padding: 1rem;
}
@media only screen and (max-width: 35em) {
  figure {
    grid-column: 1/-1;
    grid-template-rows: 92% 8%;
  }
}

.figure--img {
  grid-row: 1/2;
  margin: auto;
  justify-items: center;

  /* align-self: center; */
}
img {
  object-fit: contain;
  width: 85%;
  display: block;
  margin: 0 auto;
  /* justify-self: center;
    align-self: center; */
}
@media only screen and (max-width: 35em) {
  img {
    width: 70%;
  }
}

.figure--description {
  padding: 0 0 0.5rem 0.5rem;
  grid-row: 2/3;
  font-size: 1.6rem;
  text-align: center;
  color: var(--color-home-2);
}
@media only screen and (max-width: 35em) {
  .figure--description {
    font-size: 1.4rem;
  }
}

.content__text {
  grid-column: 2/3;
  margin: 3.5rem;
  position: relative;
  font-size: 1.8rem;
  color: var(--color-grey-4);
  text-align: center;
}
@media only screen and (max-width: 56.25em) {
  .content__text {
    margin: 4rem;
  }
}
@media only screen and (max-width: 35em) {
  .content__text {
    margin: 2rem;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }
}
.content__text--heading {
  text-align: center;
  align-self: center;
  text-transform: capitalize;
}
.content__text--details {
  margin: 4rem 1.5rem;
  font-weight: 900;
}
.details__item {
  display: flex;
  flex-direction: row;
}
.details__item--1 {
  text-transform: capitalize;
  width: 40%;
}
.details__item--2 {
  text-align: left;
  width: 60%;
  border-bottom: 1px solid var(--color-primary-3);
}

.capitalize {
  text-transform: capitalize;
}
.mindat {
  background-color: var(--color-primary-4);
  padding: 0.3rem;
}

a:link,
a:visited {
  color: var(--color-home-2);
}

a:hover {
  color: var(--color-primary-2);
}
</style>
