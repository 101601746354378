import { createApp, defineAsyncComponent } from "vue";
import { createHead } from "@unhead/vue";

import router from "./router.js";
import store from "./store/index.js";

import App from "./App.vue";
import "./assets/reset.css";

import BaseFinder from "./components/finder/BaseFinder.vue";
import BaseFinderHeader from "./components/finder/BaseFinderHeader.vue";
import BaseLogo from "../src/components/ui/BaseLogo.vue";
import BasePhotofiller from "../src/components/ui/BasePhotofiller.vue";
import BaseSelect from "./components/finder/BaseSelect.vue";
import BaseSocial from "./components/ui/BaseSocial.vue";
// Buttons
import ActionButton from "../src/components/buttons/ActionButton.vue";
import CloseButton from "../src/components/buttons/CloseButton.vue";
import LanguageButton from "../src/components/buttons/LanguageButton.vue";
import NaviButton from "../src/components/buttons/NaviButton.vue";

const BaseDialog = defineAsyncComponent(() =>
  import("./components/ui/BaseDialog.vue")
);

const app = createApp(App);
const head = createHead(); // head

app.use(router);
app.use(store);
app.use(head); // head

app.component("action-button", ActionButton);
app.component("close-button", CloseButton);
app.component("base-dialog", BaseDialog);
app.component("base-finder", BaseFinder);
app.component("base-finderHeader", BaseFinderHeader);
app.component("base-logo", BaseLogo);
app.component("base-photofiller", BasePhotofiller);
app.component("base-select", BaseSelect);
app.component("base-social", BaseSocial);
app.component("language-button", LanguageButton);
app.component("navi-button", NaviButton);

app.mount("#app");
