<template>
  <div>
    <input type="checkbox" class="checkbox" id="toggle" />
    <label for="toggle" :type="type" :class="mode">
      <span> &nbsp; </span>
    </label>
    <div class="back">&nbsp;</div>
    <nav>
      <ul>
        <li><router-link to="/"> home </router-link></li>
        <li>
          <router-link to="/minerals">{{
            nav.mineral[getCurrentLanguage]
          }}</router-link>
        </li>

        <li>
          <router-link to="/about">{{
            nav.lupanalua[getCurrentLanguage]
          }}</router-link>
        </li>
        <li>
          <base-social
            mode="social-nav"
            :share-url="shareUrl"
            :icon-class="iconClass"
            :button-class="buttonClass"
          ></base-social>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import { useStore } from "vuex";
defineProps({
  type: String,
  mode: String,
});
const nav = ref({
  lupanalua: {
    en: "lupanalua",
    pt: "lupanalua",
  },
  mineral: {
    en: "minerals",
    pt: "minerais",
  },
  rock: {
    en: "rocks",
    pt: "rochas",
  },
});

const store = useStore();
const getCurrentLanguage = computed(() => {
  return store.getters.getCurrentLanguage;
});
/** social network buttons */
const shareUrl = ref({
  title: "lupanalua",
  text: "Minerals database searchable by type, composition and other characteristics",
  url: "https://www.lupanalua.com/minerals",
});

const buttonClass = {
  basic: "social--action-home",
};
const iconClass = {
  basic: "icon--home-basic",
};
</script>

<style scoped>
div {
  --sizeBtn: 5rem;
  --verBotPos: 1rem; /* bottom: vertical bottom position*/
  --verMedPos: 30rem; /* top: vertical medium position */
  --horPos: 1rem; /* left: horizontal position */
}

input {
  display: none;
}
input:checked ~ .back {
  transform: scale(80);
}
input:checked ~ nav {
  opacity: 1;
  width: 25rem;
  background-color: white;
  box-shadow: 0 1rem 3rem var(--color-grey-4);
}
input:checked + label span {
  background-color: transparent;
}
input:checked + label span::before {
  top: 0;
  transform: rotate(135deg);
}
input:checked + label span::after {
  top: 0;
  transform: rotate(-135deg);
}
/* button: if you change this position, change back as well */
label {
  background-color: white;
  position: fixed;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem var(--color-grey-4);
  text-align: center;
  cursor: pointer;
}

.middle {
  width: var(--sizeBtn);
  height: var(--sizeBtn);
  top: var(--verMedPos);
  left: var(--horPos);
}

@media only screen and (max-width: 48em) {
  .middle {
    background-color: var(--color-primary-1);
  }
}

label:hover span::before {
  top: -1rem;
}
label:hover span::after {
  top: 1rem;
}
/* icon */
span {
  position: relative;
  margin-top: 2.5rem;
}
span,
span::before,
span::after {
  width: 2.5rem;
  height: 2px;
  background-color: var(--color-grey-4);
  display: inline-block;
}
@media only screen and (max-width: 48em) {
  span,
  span::before,
  span::after {
    background-color: white;
  }
}

span::before,
span::after {
  content: "";
  position: absolute;
  left: 0;
  transition: 0 0.2s;
}

span::before {
  top: -0.8rem;
}
span::after {
  top: 0.8rem;
}
/* background */

.back {
  background-color: transparent;
  width: calc(var(--sizeBtn) - 1rem);
  height: calc(var(--sizeBtn) - 1rem);
  position: fixed;
  top: calc(var(--verMedPos) + 0.5rem);
  left: calc(var(--horPos) + 0.5rem);
  z-index: 1500;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
/* navigation container */
nav {
  z-index: 1700;
  height: 30rem;
  position: fixed;
  top: 26rem;
  left: 4rem;
  opacity: 0;
  width: 0;

  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

ul {
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

li {
  margin: 1rem;
  font-size: 3rem;
}
li:hover:not(:last-child) {
  background-color: var(--color-primary-1);
}
</style>
