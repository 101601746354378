<template>
  <button class="btn navBtn" :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ["mode"],
};
</script>

<style scoped>
.navBtn {
  fill: var(--color-white);
  transition: all 0.2s;
}
@media only screen and (max-width: 23.75em) {
  .navBtn {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.navBtn:hover {
  transform: scale(1.5);
}

.refresh {
  position: fixed;
  top: 55%;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
}

.filter {
  position: fixed;
  top: 30%;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
}
/**minerals */
.goUp {
  position: fixed;
  bottom: 10%;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
}
.home-left {
  position: fixed;
  top: 50%;
  left: 20rem;
  width: 5rem;
  height: 5rem;
  transform: rotate(0.25turn);
  border-radius: 50%;
  fill: var(--color-primary-3);
}

.home-left:hover {
  transform: rotate(0.25turn) scale(1.2);
}
.home-right {
  position: fixed;
  top: 50%;
  right: 20rem;
  width: 5rem;
  height: 5rem;
  transform: rotate(0.75turn);
  border-radius: 50%;
  fill: var(--color-primary-3);
}
.home-right:hover {
  transform: rotate(0.75turn) scale(1.2);
}

@media only screen and (max-width: 62.5em) {
  .home-left,
  .home-right {
    top: 95%;
  }
}
/* social - homepage -- icon size: 2remx2rem*/
.social--action-home {
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid var(--color-primary-3);
  border-radius: 50%;
  align-items: center;
  box-shadow: 2px 2px var(--color-primary-2);
}
.social--action-home:hover {
  transform: none;
  background-color: var(--color-primary-2);
  fill: white;
  stroke: white;
  border: white;
  box-shadow: 2px 2px 2px var(--color-primary-3);
}
.social--action-finder {
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid var(--color-primary-3);
  border-radius: 50%;
  background-color: white;
  align-items: center;
  box-shadow: 2px 2px var(--color-primary-2);
}
.social--action-finder:hover {
  transform: none;
  background-color: var(--color-primary-2);
  fill: white;
  stroke: white;
  border: white;
  box-shadow: 2px 2px 2px white;
}
</style>
